// Reducer action types
export const STORE_ALL_AGENCIES_REQUEST_INIT = 'STORE_ALL_AGENCIES_REQUEST_INIT';
export const STORE_ALL_AGENCIES_REQUEST_RESET = 'STORE_ALL_AGENCIES_REQUEST_RESET';
export const STORE_ALL_AGENCIES_REQUEST_FAILED = 'STORE_ALL_AGENCIES_REQUEST_FAILED';
export const STORE_ALL_AGENCIES_REQUEST_SUCCEEDED = 'STORE_ALL_AGENCIES_REQUEST_SUCCEEDED';

// ======================================================== All agencies
// Set all agencies init data into store
export const storeAllAgenciesRequestInit = () => ({
    type: STORE_ALL_AGENCIES_REQUEST_INIT
});

// Set all agencies failed data into store
export const storeAllAgenciesRequestFailed = ({message}) => ({
    message,
    type: STORE_ALL_AGENCIES_REQUEST_FAILED
});

// Set all agencies succeeded data into store
export const storeAllAgenciesRequestSucceed = ({message}) => ({
    message,
    type: STORE_ALL_AGENCIES_REQUEST_SUCCEEDED
});

// Set all agencies reset data into store
export const storeAllAgenciesRequestReset = () => ({
    type: STORE_ALL_AGENCIES_REQUEST_RESET
});
