import { all, takeLatest, put, fork, call } from 'redux-saga/effects'

import * as api from "../../constants/apiConstants";
import {apiGetRequest} from "../../functions/axiosFunctions";
import {storeSetAgenciesData, EMIT_ALL_AGENCIES_FETCH} from "./actions";
import {
    storeAllAgenciesRequestInit,
    storeAllAgenciesRequestFailed,
    storeAllAgenciesRequestSucceed,
} from "../requests/agencies/actions";

// Fetch all agencies from API
export function* emitAllAgenciesFetch() {
    yield takeLatest(EMIT_ALL_AGENCIES_FETCH, function*() {
        try {
            // Fire event for request
            yield put(storeAllAgenciesRequestInit());
            const apiResponse = yield call(apiGetRequest, api.ALL_AGENCIES_API_PATH);
            // Extract data
            const agencies = extractAgenciesData(apiResponse.data.agencies);
            // Fire event to redux
            yield put(storeSetAgenciesData({agencies, hasMoreData: false, page: 0}));
            // Fire event for request
            yield put(storeAllAgenciesRequestSucceed({message: apiResponse.message}));
        } catch (message) {
            // Fire event for request
            yield put(storeAllAgenciesRequestFailed({message}));
        }
    });
}

// Extract zone data
function extractAgencyData(apiAgency, apiManager, apiSims) {
    let agency = {
        id: '', name: '', description: '', creation: '',

        sims: [],
        manager: {id: '', name: ''},
    };
    if(apiSims) {
        apiSims.forEach(data => {
            agency.sims.push({
                name: data.nom,
                number: data.numero,
                balance: data.solde,
                id: data.id.toString(),
                creation: data.created_at
            })
        });
    }
    if(apiManager) {
        agency.manager = {
            name: apiManager.name,
            id: apiManager.id.toString(),
        }
    }
    if(apiAgency) {
        agency.actionLoader = false;
        agency.name = apiAgency.name;
        agency.id = apiAgency.id.toString();
        agency.creation = apiAgency.created_at;
        agency.description = apiAgency.description;
    }
    return agency;
}

// Extract zones data
function extractAgenciesData(apiAgencies) {
    const agencies = [];
    if(apiAgencies) {
        apiAgencies.forEach(data => {
            agencies.push(extractAgencyData(
                data.agency,
                data.manager,
                data.puces,
            ));
        });
    }
    return agencies;
}

// Combine to export all functions at once
export default function* sagaAgencies() {
    yield all([
        fork(emitAllAgenciesFetch),
    ]);
}
