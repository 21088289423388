// Reducer action types
export const STORE_SET_AGENCIES_DATA = 'STORE_SET_AGENCIES_DATA';

// Middleware action types
export const EMIT_ALL_AGENCIES_FETCH = 'EMIT_ALL_AGENCIES_FETCH';

//====================== Reducer trigger actions
// Set agencies data in store
export const storeSetAgenciesData = ({agencies, hasMoreData, page}) => ({
    page,
    agencies,
    hasMoreData,
    type: STORE_SET_AGENCIES_DATA
});

//====================== Middleware trigger actions
// Emit all agencies fetch
export const emitAllAgenciesFetch = () => ({
    type: EMIT_ALL_AGENCIES_FETCH
});
